import {
  ExportButton,
  SortButton,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { API_BASE_URL, API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { SORTING_VALUES } from 'shared/constants/sorting-values';
import { httpClient } from 'shared/services/http-client';
import XLSX from 'xlsx';

export const TherapistListActions = () => {
  const records = useListContext();

  return (
    <TopToolbar>
      <SortButton
        fields={[SORTING_VALUES.createdAt, SORTING_VALUES.lastname]}
      />
      <ExportButton maxResults={records.total} exporter={(...args) => {
        
        httpClient(

          `${ API_BASE_URL_ADMIN }/${ API_URL.therapistsExport }?limit=10000&page=1`,
          { method: 'GET' },

        ).then(({ json }) => {
      
          console.log({ unFilteredTherapists: json.data });

          if ( !json?.data ) { alert('Произошла ошибка при выгрузке'); return; }

          const therapists = json.data.filter(( el: any ) => !el?.isTest && !el?.deletedAt );

          console.log({ therapists });
        
          const rows = therapists.map((t: any) => {

            return {

              createdAt: t.createdAt,
              city: t.city,
              firstname: t.user.firstname,
              lastname: t.user.lastname,
              price: t.price,
              email: t.user.email,
              phone: t.user.phone,
              methods: t.therapyMethods.map(( el: any ) => el.name).join(', '),

              totalSessions: t.totalSessions,
              totalSuccessfulSessions: t.totalSuccessfulSessions,
              visitedSessions: t.visitedSessions,
              awaitingSessions: t.awaitingCount,
              canceledSessions: t.canceledCount,

              totalClients: t.totalClients,
              regularClients: t.regularClients,
              clientsWithTwoOrMoreSessions: t.clientsWithTwoOrMoreSessions,
              firstTimeClients: t.firstTimeClients,
              crr: t.crr,

            }

          })

          const worksheet = XLSX.utils.json_to_sheet(rows, { sheetStubs: true });
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");

          worksheet["!cols"] = new Array(50).fill({ width: 40 });
          XLSX.writeFile(workbook, "Therapists.xlsx", { compression: true });
      
        });

      }} />
    </TopToolbar>
  );
};
