import { Datagrid, List, TextField } from 'react-admin';
import { LABELS } from 'shared/constants/labels';

export const TherapistsRequestsList = () => {
  return (
    <List>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="personalInfo.firstname" label={LABELS.firstname} />
        <TextField source="personalInfo.lastname" label={LABELS.lastname} />
      </Datagrid>
    </List>
  );
};
