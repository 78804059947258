import { useNotify, useUpdate } from 'react-admin';
import { PaymentResponseDto } from 'shared/api';
import { API_URL } from 'shared/constants/api-url';

export interface UseSetSessionPaymentPaidArguments {
  sessionId: string;
  onSuccess?: () => void;
}

export const useSetSessionPaymentPaid = ({
  sessionId,
  onSuccess,
}: UseSetSessionPaymentPaidArguments) => {
  const notify = useNotify();

  return useUpdate<PaymentResponseDto, any>(
    API_URL.setSessionPaymentPaid,
    { id: sessionId },
    {
      onSuccess: () => {
        notify('Статус успешно изменен', { type: 'success' });
        onSuccess?.();
      },
      onError: (error) => {
        notify(error.message || 'Что-то пошло не так...', {
          type: 'error',
        });
      },
    },
  );
};
